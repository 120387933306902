<template>
    <div>
        <div class="banner-slider">
            <Slider />
        </div>
        <div class="main" ref="mainClass">
        </div>
        <div class="main-top-content">
            <div class="main-container container-toppage">
                <div class="row ml-0 mr-0 mt-4">
                    <div class="col-lg-2 col-md-3 col-sm-12">
                        <NavLeft />
                        <Banner />
                    </div>
                    <div class="col-lg-10 col-md-9 col-sm-12">
                        <PromotionTop/>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-top-content">
            <div class="container-toppage">
                <div>
                    <ApplyTeacher />
                </div>
                <div class="main">
                    <div class="row p-sm-3 p-md-4">
                        <div class="col-12">
                            <Information />
                        </div>
                    </div>
                </div>
                <AdsTop />
            </div>
        </div>
        <MetaTags/>
    </div>
</template>
<script>
import Slider from "./components/slider.vue";
import NavLeft from "@/components/pc/nav/nav-left.vue";
import Information from "./components/information/list.vue";
import ApplyTeacher from "@modules/pc/page/applyToTeacher/components/apply-to-teacher.vue";
import PromotionTop from "./promotion/index.vue";
import { mapActions } from "vuex";
import AdsTop from "./components/ads-top.vue";
import Banner from "@components/shared/banner/index.vue"

export default {
    name: "top-page",
    data(){
        return {
            marginLeft: 0
        }
    },
    components: {
        Slider,
        NavLeft,
        Information,
        ApplyTeacher,
        PromotionTop,
        AdsTop,
        Banner
    },
    async created(){
        await this.getTopPage({
            limit: 3
        });
    },
    mounted () {
        var mainClass = this.$refs.mainClass;
        var style = window.getComputedStyle(mainClass);
        this.marginLeft = style.marginLeft;
    },
    methods: {
        ...mapActions({
            getTopPage: "common/getTopPage",
        })
    }
};
</script>
<style>
    /* .banner-slider {
        margin: 0 auto;
        margin-top: 1.5rem;
        max-width: 1920px;
    } */

    .main-container {
        margin-bottom: 6rem;
    }
</style>