<template>
    <div class="container-lesson lesson" id="card_size" ref="card_size">
        <div class="see-more mr-4">
            <router-link class="pull-right" to="/c/elementaryschool/juniorhighschool-exam">
                {{ $t("lesson.see_more") }}
            </router-link>
        </div>
        <router-link to="/c/elementaryschool/juniorhighschool-exam">
            <h5 class="title">{{ $t("lesson.newest") }}</h5>
        </router-link>
        <carousel
            class="my-carousel carousel-item-w-auto"
            :items="4"
            :perPage="4"
            :stagePadding="140"
            :margin="28"
            :nav="false"
            :navText="false"
            :dots="false"
            :slideBy="4"
            :rewind="false"
            :responsive="slideResponsive"
            v-if="items.length > 0"
        >
            <template v-for="(item, key) in items">
                <router-link
                    :to="{ name: 'lesson.detail', params: { lessonId: item.id } }"
                    class="no-decoration"
                    :key="key"
                >
                    <div class="cimg">
                        <img
                            :src="item.thumbnail"
                            :alt="item.thumbnail"
                            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                            :title="item.title"
                        />
                    </div>
                    <div class="description text-center">
                        <h5 class="custom-text-overflow mt-2">{{ item.title }}</h5>
                        <div class="introduction custom-text-overflow">
                            {{ item.teacher_name }}
                        </div>
                        <div class="price text-left">
                            {{ formatCurrency(item.currency_code, item.price) }}
                        </div>
                        <div class="reviewed-star rate text-left">
                            <span class="star">
                                <star-rating
                                    :rating="Number(item.rated)"
                                    :star-size="18"
                                    :show-rating="false"
                                    :read-only="true"
                                />
                            </span>
                            <span>
                                {{ item.rated }}
                                {{ item.reviewed ? `(${item.reviewed})` : `` }}
                            </span>
                        </div>
                    </div>
                </router-link>

            </template>
            <template slot="prev" >
                <div class="slide-control">
                    <span class="prev icon control-icon">&#8592;</span>
                </div>
            </template>
            <template slot="next" >
                <div class="slide-control">
                    <span class="next icon control-icon">&#8594;</span>
                </div>
            </template>
        </carousel>
    </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
    name: "LessonNewest",
    components: {
        carousel,
    },
    props: {
        items: [],
    },
    data() {
        return {
            navMenu: [
                '<span class="icon">&#8592;</span>',
                '<span class="icon">&#8594;</span>',
            ],
            slideResponsive: {
                0: {
                    items: 2,
                    stagePadding: 0,
                    margin: 0,
                },
                600: {
                    items: 2,
                    stagePadding: 50,
                    margin: 20,
                },
                900: {
                    items: 3,
                    stagePadding: 50,
                    margin: 20,
                },
                1024: {
                    items: 4,
                    stagePadding: 140,
                    margin: 28,
                },
                1280: {
                    items: 4,
                    stagePadding: 140,
                    margin: 28,
                },
                1366: {
                    items: 4,
                    stagePadding: 140,
                    margin: 28,
                }
            },
        };
    },
};
</script>
<style scoped>
.my-carousel {
    width: 90%;
}
.slide-control {
    width: 60px;
    height: 60px;
    border: 1px solid orange;
    border-radius: 50%;
    position: absolute;
    right: 3%;
    top: 43%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.control-icon {
    color: orange;
    font-size: 1.5rem;
    font-weight: bolder;
    position: relative;
    top: 14%;
    left: 0;
}
.slide-control::after {
    color: orange;
}

.slide-control:hover {
    cursor: pointer;
}

.my-carousel .owl-nav {
    background-color: red;
    border: 1px solid red;
}
.my-carousel .owl-theme .owl-nav {
    background-color: red;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
    .slide-control {
        right: 2%;
        top: 45%;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .slide-control {
        right: 1%;
        top: 45%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}
</style>

