<template>
    <div class="container-lesson teacher" id="card_size" ref="card_size">
        <div class="see-more mr-4">
            <router-link class="pull-right" to="/c/all?tab=teacher&teacher_type=expert&sorter=recommend-ASC&type=category">
                {{ $t("lesson.see_more") }}
            </router-link>
        </div>
        <router-link to="/c/all?tab=teacher&teacher_type=expert&sorter=recommend-ASC&type=category">
            <h5 class="title">{{ $t("lesson.teacher_expert") }}</h5>
        </router-link>
        <carousel
            :items="4"
            :stagePadding="140"
            :margin="28"
            :nav="false"
            :navText="false"
            :dots="false"
            :slideBy="4"
            :rewind="false"
            :responsive="slideResponsive"
            v-if="items.length > 0"
            class="my-carousel carousel-item-w-auto"
        >

            <template v-for="(item, key) in items">
                <router-link
                    :key="key"
                    :to="{
                        name: 'teacher-profile',
                        params: { teacherId: item.member_id },
                    }"
                    class="no-decoration"
                >
                    <div class="cimg">
                        <img
                            :src="item.profile_image"
                            :alt="item.profile_image"
                            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                            :title="item.teacher_name"
                            ref="image_height"
                        />
                    </div>
                    <div class="description text-center">
                        <div class="info">{{ item.category }}</div>
                        <h5 class="teacher-name">{{ item.teacher_name }}</h5>
                        <div class="introduction custom-text-overflow">
                            {{ item.introduction }}
                        </div>
                        <div class="reviewed-star rate text-left">
                            <span class="star">
                                <star-rating
                                    :rating="Number(item.rated)"
                                    :star-size="18"
                                    :show-rating="false"
                                    :read-only="true"
                                />
                            </span>
                            <span>{{ item.rated }}
                            {{ item.reviewed ? `(${item.reviewed})` : `` }}
                            </span>
                        </div>
                    </div>
                </router-link>
                <!-- <router-link v-bind:key="key*100" to="/to-sell" class="no-decoration" v-if="key === 1">
                    <div class="cimg">
                        <img
                            src="/img/recruitment02.png"
                            alt="Join us"
                        />
                    </div>
                </router-link> -->
            </template>
            <template slot="prev" >
                <div class="slide-control">
                    <span class="prev icon control-icon">&#8592;</span>
                </div>
            </template>
            <template slot="next" >
                <div class="slide-control">
                    <span class="next icon control-icon">&#8594;</span>
                </div>
            </template>
        </carousel>
    </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
    name: "TeacherExpert",
    components: {
        carousel,
    },
    props: {
        items: [],
    },
    data() {
        return {
            navMenu: [
                '<span class="icon">&#8592;</span>',
                '<span class="icon">&#8594;</span>',
            ],
            defaultSlideHeight: 0,
            slideResponsive: {
                0: {
                    items: 2,
                    stagePadding: 0,
                    margin: 0,
                },
                600: {
                    items: 3,
                    stagePadding: 50,
                    margin: 20,
                },
                1024: {
                    items: 4,
                    stagePadding: 140,
                    margin: 28,
                },
                1280: {
                    items: 4,
                    stagePadding: 140,
                    margin: 28,
                },
                1366: {
                    items: 4,
                    stagePadding: 140,
                    margin: 28,
                },
            },
        };
    },
};
</script>
<style scoped >
    .my-carousel {
        width: 90%;
    }
    .slide-control {
        width: 60px;
        height: 60px;
        border: 1px solid orange;
        border-radius: 50%;
        position: absolute;
        right: 3%;
        top: 45%;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .control-icon {
        color: orange;
        font-size: 1.5rem;
        font-weight: bolder;
        position: relative;
        top: 14%;
        left: 0;
    }
    .slide-control::after {
        color: orange;
    }

    .slide-control:hover {
        cursor: pointer;
    }

    .teacher-ads {
        width: 100%;
        height: 240px;
        border-radius: 10%;
        background-color: orange;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        .slide-control {
            right: 2%;
            top: 45%;
        }
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        .slide-control {
            right: 1%;
            top: 45%;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
    }
</style>